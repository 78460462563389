<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	
	import draggable from 'vuedraggable'

	export default {
		name: "AppCities",
		extends: Base,
		components:{
			draggable
		},
		data() {
			return {
				Name: "AppCities",
				MrCities: {},
				Point: {},
			}
		},
		computed:{
			 page(){ return this.$root.page }
		},
		mounted() {
			Gen.loadCss("/backend/assets/custom_l9.css")
			this.$set(this.$root, 'page', this)
			this.refreshData()

			$(document).ready(function () {
				$(".map-pointer").each(function () {
					$(this).click(function () {
						var dataAddr = $(this).attr("data-content");
						var cardOff = $(".card.border p[data-content='"+dataAddr+"']").parents(".card.border").offset();
						$("html, body").animate({scrollTop: cardOff.top - $(window).height()/3}, 300);
					})
				})
			})
		},
		methods:{
			refreshData(cb, param = {}){
				this.data.data = false
				var params = Object.assign(param, this.$route.query)
				this.ajaxRefreshData = BOGen.apirest("/"+this.Name+(this.$route.params.id?"/"+this.$route.params.id:""), params, (err, resp)=>{
					if(err) return;
						Object.keys(resp).forEach((k)=>{
						this[k] = resp[k]
					})
					if(this.$route.params.id){
						this.row.type = this.$route.params.id == "add" ? "add" : "update"
					}
					$('[data-toggle="popover"]').popover()
					if(this.onRefreshPage) return this.onRefreshPage(resp)
					if(cb) cb(resp)
				})
			},
			choosePoint(){
				var x = event.pageX - $("#mainMap").offset().left;
				var y = event.pageY - $("#mainMap").offset().top;
				var leftPercent = x/$("#mainMap").width()*100;
				var topPercent = y/$("#mainMap").height()*100;
				this.row.acs_point_x = leftPercent
				this.row.acs_point_y = topPercent
				
				$("#mainMap .map-pointer").remove();
				$("#mainMap").append("<a class='map-pointer' style='top: "+topPercent+"%;  left: "+leftPercent+"%;'>");
				console.log(leftPercent+"%, "+topPercent+"%");
			},
			deleteItem(id,k,k2){
				swal({
					title: "Are you sure to delete "+this.ObjectName+"?",
					text:"",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
					BOGen.apirest("/"+this.Name, {type:"delete",id:id},()=>{
						// callback delete item
					this.data[k].data.splice(k2, 1);
					}, "POST")
					this.refreshData()
					swal("Deleted!", "Information has been deleted.", "success");
					}
				});
			},
			endDrag() {
				BOGen.apirest('/' + Page.Name, {
					data: this.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			}
		},
		watch: {
			'$route.query'() {
				this.refreshData()
				$("#mainMap").append("<a class='map-pointer' style='top: "+34+"%;  left: "+23+"%;'>");
				if(this.$route.params.id) {
					let leftPercent = this.row.acs_point_x
					let topPercent = this.row.acs_point_y
					console.log(parseInt(leftPercent)+' '+parseInt(topPercent))
				}
			},
			'filter.level'() {
				this.search()
			},
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Our Cities</h5>
						<!-- <div class="d-flex justify-content-between align-items-center"> -->
						<!-- </div> -->
						<hr>
						<div class="form-group">
							<div class="indo-map">
								<img :src="assets('fo_images','indo_map.png')" alt="">
									<a v-for="(v,k) in Point" class="map-pointer" :key="k" data-container="body" data-toggle="popover" data-trigger="hover"
									data-placement="bottom" :data-content="v.acs_address" aria-describedby="popover320813" :style="'top: '+v.acs_point_y+'%; left: '+v.acs_point_x+'%;'"></a>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Our Cities List <span class="card-subtitle">Drag cities to move the position.</span></h5>
						<hr>
					</div>
				</div>
				<!-- <div class="card"> -->
				<draggable class="card" v-model="data" tag="div" @end="endDrag">
					<div class="card-body" v-for="(v,k) in data" :key="k">
						<h5 class="card-title">{{v.mc_name}}</h5>
						<hr>
						<div class="row">
							<div class="col-md-4" v-for="(v2,k2) in v.data" :key="k2">
								<div class="card border">
									<div class="card-body">
										<p :data-content="v2.acs_address">
											{{v2.acs_address}}
										</p>
										<p class="mb-0">
											<a href="javascript:;" v-if="page.moduleRole('Remove')" @click="deleteItem(v2.acs_id,k,k2)" class="text-inverse icon_action mr-2"><i class="ti-trash"></i></a>
											<router-link :to="{name:Name,params:{id:v2.acs_id}}" v-if="page.moduleRole('Update')" class="text-inverse icon_action"><i class="ti-pencil"></i></router-link>
											<span v-if="v2.acs_is_active=='Y'" class="label label-success float-right">Active</span>
											<span v-else class="label label-danger float-right">Inctive</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</draggable>
				<!-- </div> -->
			</div>
		</div>
		<div class="row" v-if="$route.params.id">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title">Add Cities</h5>
						</div>
						<hr>
						<VForm @resp="submitForm">
							<div class="info"></div>
							<div class="row">
								<div class="col-md-8">
									<div class="row">
										<div class="col-8">
											<div class="form-group">
												<label for="">City</label>
												<InputSuggest v-model="row.acs_city" :url="'/select-cities?search='+row.acs_city"></InputSuggest>
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<label for="">Full Address</label>
												<textarea v-model="row.acs_address" class="form-control" rows="6" placeholder="Enter full address ..."></textarea>
											</div>
										</div>
										<div class="col-8">
											<BoField name="acs_is_active">
											<div class="row">
												<div class="col-2">
													<radio name="acs_is_active" v-model="row.acs_is_active" option="Y" :attr="validation('acs_is_active')">Active</radio>
												</div>
												<div class="col-2">
													<radio name="acs_is_active" v-model="row.acs_is_active" option="N">Inactive</radio>
												</div>
											</div>
										</BoField>
										</div>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<label for="">Location on Map (Click to generate)</label>
										<div class="indo-map" id="mainMap" @click="choosePoint">
											<img :src="assets('fo_images','indo_map.png')" alt="">
											<a v-if="row.acs_city" class="map-pointer" :style="'top: '+row.acs_point_y+'%;  left: '+row.acs_point_x+'%;'"></a>
										</div>
									</div>
								</div>
							</div>
							<hr>
							<div class="d-flex justify-content-end">
								<button type="submit" class="btn btn-success">Submit</button>
							</div>
						</VForm>
						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>